/* You can add global styles to this file, and also import other style files */

* {
  //font-family: Helvetica, Tahoma, Arial, "PingFang SC",
  //"Hiragino Sans GB", "Heiti SC", STXihei, "Microsoft YaHei",
  //SimHei, "WenQuanYi Micro Hei", sans-serif;
  font-family: PingFangSC-Regular, PingFang SC;
}

//.ant-card-head {
//  min-height: auto;
//  padding: 0 20px
//
//  //& > .ant-tabs {
//  //  margin-bottom: 0;
//  //}
//}

.alain-default__content {
  margin-bottom: 0;
  //margin-left: 214px !important;
}

.alain-default__nav-item {
  display: flex;
  align-items: center;
  padding: 10px 10px;

  .anticon {
    padding: 0 5px;

    &:last-child {
      padding: 0 0 0 5px;
    }

    &:first-child {
      padding: 0 5px 0 0;
    }
  }
}

.text-danger {
  color: #f00;
}

.text-danger-info {
  color: rgba(232, 133, 12, 0.93);
}

td.indicator {
  background-color: rgba(126, 193, 25, 0.1);
}

.pl-2 {
  padding-left: 20px;
}

.stat-block {
  padding: 24px 0;
  margin-bottom: 16px;
  margin-right: 16px;
  border: 1px solid #f0f0f0;
  flex-grow: 1;
  display: flex;
  justify-content: center;

  &.small:last-child,
  &:last-child {
    margin-right: 0;
  }

  &.small {
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 12px 0;

    & > div {
      padding: 0 7px;

      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }
  }

  & > div {
    position: relative;
    padding: 0 14px;
    text-align: center;

    & > p:first-child {
      margin-bottom: 4px;
      color: #00000073;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }

    & > p {
      margin: 0;
      color: #000000d9;
      font-size: 30px;
      line-height: 38px;

      & > span {
        color: #00000073;
        font-size: 14px;
      }
    }

    &::after {
      position: absolute;
      top: 20%;
      right: 0;
      width: 1px;
      height: 60%;
      background-color: #f0f0f0;
      content: ' ';
    }

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;

      &::after {
        display: none;
      }
    }
  }
}

.small .stat-block-color {
  min-width: 80px;
  margin-right: 12px;
  padding: 12px;
}

.stat-block-color {
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  flex-grow: 1;
  margin-right: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin: 0;
  }

  .count {
    flex-grow: 1;
    font-size: 24px;
    font-weight: 600;
    color: #555;
    display: flex;
    align-items: baseline;
    justify-content: center;

    span {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .title {
    width: 100%;
    background-color: #ddd;
    text-align: center;
    color: #fff;
    padding: 2px 10px;
  }
}

.special-indicator-card {
  .ant-card-body {
    height: calc(100vh - 155px);
  }
}

.indicator-card {
  .ant-card-body {
    //height: calc(100vh - 160px);
  }

  .ant-card-head-title::before {
    content: ' ';
    padding-left: 10px;
    border-left: 4px solid #2f68e9;
  }

  .ant-card-head-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
  }
}

.mr-1 {
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
}

.ant-card {
  margin-bottom: 10px;
  //box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.1);
}

.small.ant-card .ant-card-body {
  padding: 12px 24px;
}

.tab.ant-card .ant-card-body {
  padding-bottom: 0;

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
}

.nb-page-header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 20px;
  //width: 100vw;
  //position: absolute;
  //top: 8px;
  //color: rgba(0, 0, 0, 0.45);
  //background: #fff;

  .page-back-icon {
    cursor: pointer;
    margin-right: 10px;
  }

  .page-divide {
    display: inline-block;
    margin: 0 12px;
  }

  .page-header-title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
  }
}

.nb-divider {
  width: 100%;
  height: 20px;
  border-bottom: 1px dashed #d1d1d1;
}

.equal-height {
  display: flex;
  align-items: stretch;
}

.equal-height-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.ant-card-body {
  padding: 20px;
}

.indicator-tabs {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 6vw;
  }
}

.table-head-left {
  text-align: left;
  margin-bottom: 10px;
}

.table-head-right {
  text-align: right;
  margin-bottom: 10px;
  padding-right: 24px;
}

//新增统一表单样式
.form-add-frame {
  width: 100%;
  min-width: 1230px;
  min-height: calc(100vh - 125px);
  background: #ffffff;
  border-radius: 8px;

  .form-head {
    height: 62px;
    padding: 0 20px;
    border-bottom: 4px solid #eef0fb;
    display: flex;
    justify-content: space-between;

    .form-head-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 62px;
    }

    .form-head-close-icon {
      font-size: 20px;
      padding: 20px 0;
      cursor: pointer;
    }
  }

  .form-content {
    display: flex;
    justify-content: space-between;

    .form-content-left {
      width: calc(100% - 200px);
      min-height: calc(100vh - 188px);
      border-right: 1px solid #d1d1d1;

      .form-content-part {
        padding: 0 10px 20px 10px;
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 10px;

        .content-part-title {
          display: flex;
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          margin: 20px 0;

          .title-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #0091ff;
            margin: 5px 6px 5px 0;
          }
        }
      }

      .form-button-group {
        padding: 30px 0;
        text-align: center;
        margin-right: 48px;
        min-height: 50vh;
      }
    }

    .form-content-right {
      margin-top: 30px;
      width: 180px;
      //margin-left: 20px;
      padding-left: 26px;
      height: 100%;
    }
  }
}

.form-content-label-wrap {
  .ant-form-item-label {
    white-space: normal !important;
    line-height: 18px;
  }
}

.form-content {
  // 表单label左对齐
  .ant-form-item {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }

  .ant-form-item-label {
    text-align: left !important;
    min-width: 110px;
  }

  .ant-form-item-label > label {
    margin-left: 10px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute !important;
    top: 4px;
    left: -9px;
  }
}

//只读输入框
.input-readonly-bg {
  background: rgba(242, 242, 242, 1);
}

// 上传附件并排内容样式
.file-box {
  position: relative;

  .tip-text {
    font-size: 12px;
    color: #999;
    position: absolute;
    top: 0;
    left: 120px;
    line-height: 30px;
    min-height: 32px;
  }

  .tip-date {
    width: 70%;
    position: absolute;
    top: 0;
    left: 93px;
  }
}

.radio-margin-left {
  margin-left: 30px;
}

.ant-radio-wrapper {
  margin-right: 30px;
}

.form-require-icon {
  color: red;
  position: relative;
  top: -5px;
  font-size: 16px;
}

.form-row-bg {
  background: rgba(245, 245, 245, 0.8);
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  padding-top: 15px !important;
  margin-bottom: 10px;
}

.form-box {
  // 表单label左对齐
  .ant-form-item {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }

  .ant-form-item-label {
    text-align: left !important;
    min-width: 100px;
  }

  .ant-form-item-label > label {
    margin-left: 10px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute !important;
    top: 4px;
    left: -9px;
  }
}

.form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  .form-col-1 {
    width: 100%;
  }

  .form-col-2 {
    width: calc(50% - 20px);
    //    //border: 1px solid green;
  }

  .form-col-3 {
    width: calc(33% - 20px);
    //    //border: 1px solid green;
  }
}

.node-title {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;

  .node-dot {
    width: 0;
    height: 0;
    border-top-width: 5px;
    border-right-width: 5px;
    border-left-width: 10px;
    border-bottom-width: 5px;
    border-color: transparent transparent transparent rgba(0, 145, 255, 1);
    border-style: solid;
    margin: 5px 3px 5px 10px;
  }
}

// 表格统一颜色修改
.ant-table-container table > thead > tr th {
  background: #f4faff;
  //background: rgba(228, 243, 255, 1);
}

.ant-table-small .ant-table-thead > tr > th {
  background: #f4faff;
  //background: rgba(228, 243, 255, 1);
}

//.ant-table-container table > thead > tr:first-child th:last-child {
//  border-top-right-radius: 2px;
//  background: rgba(228, 243, 255, 0.5);
//}
//
.ant-table table > tbody tr:nth-child(odd) {
  background: #fff;
}

.ant-table table > tbody tr:nth-child(even) {
  background: #f7f7f7;
}

.ant-table table > tbody tr:nth-child(odd) .ant-table-cell-fix-left {
  background: #fff;
}

.ant-table table > tbody tr:nth-child(even) .ant-table-cell-fix-left {
  background: #f7f7f7;
}

.ant-table table > tbody tr:nth-child(odd) .ant-table-cell-fix-right {
  background: #fff;
}

.ant-table table > tbody tr:nth-child(even) .ant-table-cell-fix-right {
  background: #f7f7f7;
}

.ant-table .ant-table-expanded-row-fixed {
  background: #fff;
}

// 表格房源弹窗有树形帅选暂无数据没有居中特殊处理
.tree-house-list-modal-table .ant-table-expanded-row-fixed {
  width: 60vw !important;
}

// 表格房源弹窗无树形帅选暂无数据没有居中特殊处理
.house-list-modal-table .ant-table-expanded-row-fixed {
  width: 80vw !important;
}

/**
 * 可视化 1920/1080
 */

html {
  font-size: (1 / 1080) * 100vh;
}
