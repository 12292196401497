// You can directly set the default theme
// - `default` Default theme
// - `dark` Import the official dark less style file
// - `compact` Import the official compact less style file
@import '~@delon/theme/theme-default.less';

// ==========The following is the custom theme variable area==========
// The theme paraments can be generated at https://ng-alain.github.io/ng-alain/
// @primary-color: #f50;
//@primary-color: #1f3de2;
.alain-default {
  background: #faf7f7 !important;
}

.alain-default__header-logo-expanded,
.alain-default__header-logo-collapsed {
  max-height: 48px;
}

.alain-default__header {
  background: #007aff;
}

.py-lg {
  display: flex;
  padding-top: 0 !important;
  padding-bottom: 24px !important;
  justify-content: space-between;
}

.alain-default .page-header {
  display: block;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: -23px;
  border-bottom: none;
  background: none;
  height: 40px;
  line-height: 40px;
}

.ant-input-number {
  width: auto;
}

.nav-bar-frame {
  width: 100%;
  background: #ffffff;
  padding: 4px 20px 0 20px;
  margin-bottom: 10px;

  ::ng-deep .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    border-bottom: 1px solid #ffffff;
  }
}
