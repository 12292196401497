@charset "UTF-8";
::-webkit-scrollbar {
  width: 8px;
  /*对垂直流动条有效*/
  height: 8px;
  /*对水平流动条有效*/
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #dcdfe6;
}

.td-long-deal {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-box {
  padding: 8px;
}

.search-box input {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.search-box button {
  width: 90px;
}

.search-button {
  margin-right: 8px;
}

::ng-deep .text-center {
  text-align: left !important;
}

.shadow {
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.1);
}

.filter-bg {
  width: 100%;
  height: auto;
  background: rgba(239, 239, 239, 0.5);
  border-radius: 2px;
  padding: 10px 0 0 10px;
  margin-bottom: 10px;
}
.filter-bg .ant-form-item-label {
  text-align: left !important;
  min-width: 90px;
}

.filter-bg-form-item-bottom .ant-form-item {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.list-search-part .ant-select-selection-item {
  max-width: 10vw;
}
.list-search-part .ant-form-item {
  display: flex;
  flex-wrap: nowrap;
}
.list-search-part .ant-form-item-label {
  text-align: left !important;
  min-width: 90px;
}
.list-search-part .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.list-search-part .form-row .form-col-4 {
  width: calc(25% - 20px);
}
.list-search-part .form-row .form-col-3 {
  width: calc(33% - 25px);
}
.list-search-part .form-row .form-col-2 {
  width: calc(50% - 20px);
}

.inventory-left {
  width: calc(100% - 200px);
}

.part-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-between-auto {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.link-operate {
  color: #0091ff;
  cursor: pointer;
}

.part {
  padding: 15px 20px 20px 20px;
}
.part .basic-info .type {
  display: flex;
  font-size: 16px;
  height: 20px;
  font-weight: 600;
  color: #333;
  line-height: 20px;
  align-items: center;
}
.part .basic-info .type .type-symbol {
  width: 4px;
  height: 16px;
  background: #0091ff;
  border-radius: 2px;
  margin-right: 7px;
}

.special-table {
  font-size: 14px;
  width: 100%;
  border: 1px solid #efefef;
  padding: 0;
  work-break: break-all;
  word-wrap: break-word;
  margin: 10px 0 15px 0;
}
.special-table .special-tr {
  display: flex;
  line-height: 36px;
}
.special-table .special-td {
  border-bottom: 1px solid #efefef;
  border-right: 1px solid #efefef;
  padding: 0 10px;
}
.special-table .td-label {
  width: 150px;
  background: rgba(239, 239, 239, 0.5);
  font-weight: 500;
  color: #42637b;
}
.special-table .td-label-1 {
  width: 180px;
  background: rgba(239, 239, 239, 0.5);
  font-weight: 500;
  color: #42637b;
}
.special-table .td-value-1 {
  width: calc(100% - 150px);
  line-height: 22px;
  padding: 3px 10px;
  font-weight: 500;
  color: #686868;
  white-space: normal;
  word-break: break-all;
}
.special-table .td-value-3 {
  width: calc(33.4% - 150px);
  line-height: 22px;
  padding: 3px 10px;
  font-weight: 500;
  color: #686868;
  white-space: normal;
  word-break: break-all;
}
.special-table .td-value {
  width: calc(50% - 150px);
  line-height: 22px;
  padding: 3px 10px;
  font-weight: 500;
  color: #686868;
  white-space: normal;
  word-break: break-all;
}
.special-table .row-list {
  padding: 7px 0;
}
.special-table .row-list .row {
  height: 20px;
  line-height: 20px;
  color: #0091ff;
  cursor: pointer;
}

.special-table > .special-tr:last-child > .special-td {
  border-bottom: none;
}

.range-filter {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.range-filter .divide-symbol {
  margin: 0 10px;
}

.require-icon {
  color: red;
}

.audit-view-frame .audit-basic-info .audit-type {
  display: flex;
  font-size: 14px;
  height: 20px;
  font-weight: 600;
  color: #333;
  line-height: 20px;
  align-items: center;
}
.audit-view-frame .audit-basic-info .audit-type .audit-type-symbol {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0091ff;
  margin: 5px 6px 5px 0;
}

.audit-flow {
  min-height: 60vh;
  padding-left: 40px;
}
.audit-flow .audit-flow-each {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}
.audit-flow .audit-flow-each .flow-top {
  display: flex;
}
.audit-flow .audit-flow-each .flow-top .flow-log {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #58a5ec;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-right: 10px;
}
.audit-flow .audit-flow-each .flow-top .flow-header {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  line-height: 40px;
}
.audit-flow .audit-flow-each .flow-content {
  margin-left: 60px;
  border-left: 1px solid #d1d1d1;
  min-height: 80px;
  padding-left: 20px;
  height: auto;
}
.audit-flow .audit-flow-each .flow-content .flow-node {
  font-weight: 400;
}
.audit-flow .audit-flow-each .flow-content .flow-remark {
  width: auto;
  min-height: 32px;
  background: #efefef;
  font-size: 12px;
  font-weight: 400;
  color: #999;
  padding: 5px 10px;
  height: auto;
  line-height: 20px;
}
.audit-flow .audit-flow-each .flow-content .flow-date {
  font-size: 12px;
  font-weight: 400;
  color: #999;
}

.info-top-part {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 4px;
  min-height: 100px;
  padding: 15px 20px;
}
.info-top-part .info-top-part-head .time-tip {
  margin-left: 7px;
  font-size: 12px;
  font-weight: 400;
  color: #999;
}
.info-top-part .general-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 16px;
  line-height: 16px;
}
.info-top-part .general-info .divide {
  margin: 0 15px;
  position: relative;
  top: 1px;
}
.info-top-part .label {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}
.info-top-part .value {
  font-size: 12px;
  font-weight: 400;
  color: #333;
}
.info-top-part .value-remark {
  max-width: 800px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.img-box-show {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  overflow: hidden;
}

.img-box-hidden {
  display: inline-block;
  position: relative;
  left: 28px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.img-show {
  position: absolute;
  left: -28px;
  filter: drop-shadow(28px 0 #0091ff);
  cursor: pointer;
}

.img-hidden {
  position: absolute;
  top: 0;
  left: -28px;
  cursor: pointer;
}

.iconfont-left-right {
  margin: 0 5px;
}

.iconfont {
  cursor: pointer;
}

.table-pagination-box {
  text-align: right;
  line-height: 50px;
}

.page-content-frame {
  width: 100%;
}

.inOrOut-symbol {
  background: #f3faff;
  border-radius: 4px 4px 4px 0;
  border: 1px solid #0091ff;
  font-size: 10px;
  font-weight: 400;
  color: #0091ff;
  padding: 0 6px;
  height: 19px;
  line-height: 18px;
}

.big-title {
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  line-height: 28px;
  margin-right: 15px;
}

.normal-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.icon-button-group {
  color: #0091ff;
  font-size: 12px;
  margin-right: 5px;
}

.project-detail {
  width: 100%;
  min-width: 1230px;
}
.project-detail .status-select {
  display: inline-block;
  width: 110px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #1890ff;
  padding: 0 15px;
  border-radius: 2px;
  margin-right: 8px;
  color: #ffffff;
  background: #1890ff;
}
.project-detail .status-select-2 {
  display: inline-block;
  width: 110px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #1890ff;
  padding: 0 15px;
  border-radius: 2px;
  margin-right: 8px;
  color: #1890ff;
}
.project-detail .belong {
  margin-right: 8px;
  background: #678eee;
  border-radius: 2px;
  height: 24px;
  line-height: 24px;
  padding: 0 6px;
  color: #fff;
}
.project-detail .title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-right: 15px;
}
.project-detail .general-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 8px 0;
}
.project-detail .general-info .divide {
  margin: 0 15px;
  position: relative;
  top: 1px;
}
.project-detail .part {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 4px;
  min-height: 100px;
  padding: 15px 20px 20px 20px;
}
.project-detail .part .stat .stat-title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0 0 15px 0;
}
.project-detail .part .stat .type {
  font-size: 14px;
  font-weight: 400;
  color: #686868;
  margin: 7px 0;
  display: flex;
  align-items: center;
}
.project-detail .part .stat .type .dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #0091ff;
  border-radius: 50%;
  margin-right: 5px;
}
.project-detail .part .stat .num {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  padding-left: 10px;
}
.project-detail .part .stat-1 {
  min-width: calc(100% - 520px);
  width: 58.9%;
  border-right: 1px dashed #d1d1d1;
  padding-left: 5px;
}
.project-detail .part .stat-1 .stat-1-content {
  height: 132px;
  background: rgba(228, 243, 255, 0.5);
  border-radius: 10px;
  margin-right: 20px;
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
}
.project-detail .part .stat-1 .stat-1-content .each {
  width: calc(25% - 25px);
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project-detail .part .stat-1 .stat-1-content .each .title {
  font-size: 14px;
  font-weight: 500;
  color: #686868;
  line-height: 18px;
  margin: 5px 0 5px 0;
}
.project-detail .part .stat-1 .stat-1-content .each-2 {
  color: #fff;
  background: #0091ff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  height: 140px;
  position: relative;
  top: -10px;
}
.project-detail .part .stat-1 .stat-1-content .each-2 .title {
  color: #fff;
  margin-top: 15px;
}
.project-detail .part .stat-1 .stat-1-content .each:hover {
  color: #fff;
  background: #0091ff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  height: 140px;
  position: relative;
  top: -10px;
}
.project-detail .part .stat-1 .stat-1-content .each:hover .title {
  color: #fff;
  margin-top: 15px;
}
.project-detail .part .stat-1 .stat-1-content .each-1 {
  width: 100px;
  text-align: right;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  line-height: 30px;
}
.project-detail .part .stat-2 {
  min-width: 220px;
  padding: 0 20px;
  width: 18.3%;
  border-right: 1px dashed #d1d1d1;
}
.project-detail .part .stat-3 {
  min-width: 300px;
  width: 23.5%;
  padding: 0 20px;
}
.project-detail .label {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}
.project-detail .value {
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
.project-detail .value-remark {
  max-width: 800px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.order-detail {
  width: 100%;
  min-width: 1230px;
}
.order-detail .detail-top-left {
  display: flex;
  padding-right: 20px;
  width: calc(100% - 400px);
}
.order-detail .detail-top-left .detail-top-left-right {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 300px);
}
.order-detail .detail-top-right-chart {
  width: 400px;
  border-left: 1px dashed #d8d8d8;
  padding-left: 20px;
}
.order-detail .detail-top-right-chart .chart-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.order-detail .belong {
  margin-right: 8px;
  background: #678eee;
  border-radius: 2px;
  height: 24px;
  line-height: 24px;
  padding: 0 6px;
  color: #fff;
}
.order-detail .title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-right: 4px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.order-detail .contract-file-type {
  display: inline-block;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
  border: 1px solid #007aff;
  color: #007aff;
  margin-right: 6px;
}
.order-detail .general-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  line-height: 16px;
  flex-wrap: wrap;
}
.order-detail .general-info .divide {
  margin: 0 15px;
  position: relative;
  top: 1px;
}
.order-detail .part {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 4px;
  min-height: 100px;
  padding: 15px 20px 30px 20px;
}
.order-detail .label {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}
.order-detail .value {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  line-height: 30px;
}
.order-detail .value-remark {
  max-width: 800px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status-select {
  display: inline-block;
  width: 110px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #1890ff;
  padding: 0 15px;
  border-radius: 2px;
  margin-right: 8px;
  color: #fff;
  background: #1890ff;
}

.detail-symbol {
  height: 24px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  padding: 0 17px;
  line-height: 24px;
  margin-right: 7px;
  color: #fff;
}